import React from "react"
import { Link, useStaticQuery, graphql } from 'gatsby'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Helmet from 'react-helmet'

library.add(fab, fas, far)

const MetaData = () => {
    const data = useStaticQuery(graphql`
        query SiteMetaData {
            site {
                buildTime
                siteMetadata {
                    description
                    title
                }
            }
        }
    `)
    return data.site
}

const Layout = (props) => (
    <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="keywords" content={props.keywords} />
            <meta name="description" content={props.description} />
            <meta property="og:title" content={props.title} />
            <meta property="og:description" content={props.description} />
            <meta property="name" content={props.title} />
            <meta property="description" content={props.description} />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content={props.title} />
            <meta property="twitter:name" content={props.title} />
            <meta property="twitter:description" content={props.description} />
            <body className="bg-secondary h-100" />
        </Helmet>

        <header>
            <div className="header-banner bg-gradient p-3">
                <div className="text-white d-flex flex-row p-0 pb-3">
                    <FontAwesomeIcon className="fa-5x m-3 p-0" icon={'flask'} />
                    <div>
                        <div className="display-1 text-monospace">lab</div>
                        <div className="pl-2 p">Experimental stuff that doesn't always work too well...</div>
                    </div>
                </div>
            
                <hr className="border-white m-0 p-0"/>

                <nav className="p-0 pt-3 pb-3 navbar navbar-expand-md navbar-dark">
                    <Link title="Home" className="p-0 nav-link navbar-brand" to="/">Home</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="pl-3 collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <div className="nav navbar-nav navbar-right">
                            <span className="nav-item">
                                <Link className="nav-link fa fa-home" to="/test"> Test</Link>
                            </span>
                            <span className="nav-item">
                                <Link className="nav-link fa fa-home" to="/tfl"> Tfl</Link>
                            </span>
                        </div>
                    </div>
                </nav>

            </div>
        </header>

        <main className="bg-light p-3 container-fluid">
            {props.children}
        </main>
        
        <footer>
            <div className="p-3 mb-0">
                <div className="text-center text-white small">
                    <p>&copy; exsertus.com <Moment format="YYYY">{new Date()}</Moment></p>
                    <p className="small">Build Date:<Moment format="Do MMMM YYYY HH:mm">{MetaData.buildTime}</Moment></p>
                </div>
            </div>
        </footer>

    </>
)

export default Layout