import React from "react"
import Layout from '../components/layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>Page not found</h1>    
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
